<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleGotoInvoiceApply">申请开票</el-button>
        <el-button plain @click="handleExport" :loading="loadingExp">导出发票抬头</el-button>
        <el-button plain @click="handleBatchDownloadPic" :loading="loading">批量下载发票</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item prop="userId">
                <remote-select v-model="searchForm.userId"
                               width="200"
                               width-input="200px"
                               placement="bottom"
                               placeholder="用户"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.status" placeholder="开票状态" clearable>
                  <el-option label="申请中" :value="1"></el-option>
                  <el-option label="已开票" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.isSend" placeholder="客户发送状态" clearable>
                  <el-option label="未发送" :value="false"></el-option>
                  <el-option label="已发送" :value="true"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker type="date" placeholder="申请时间起"
                                value-format="yyyy-MM-dd"
                                v-model="searchForm.creationTimeStart"
                                clearable></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-date-picker type="date" placeholder="申请时间止" value-format="yyyy-MM-dd"
                                v-model="searchForm.creationTimeEnd" clearable></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleFind()">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="24">
            <el-table :data="tableInfo" style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column label="用户" width="180">
                <template slot-scope="scope">
                  {{ scope.row.nickName }}（{{ scope.row.mobile }}）
                </template>
              </el-table-column>
              <el-table-column prop="orderNo" label="订单编号" width="220"></el-table-column>
              <el-table-column prop="invoiceType" label="发票类型" width="120">
                <template slot-scope="scope">
                  {{ scope.row.invoiceType === 1 ? '增值税普通发票' : '增值税专用发票' }}
                </template>
              </el-table-column>
              <el-table-column prop="header" label="发票抬头" width="200"></el-table-column>
              <el-table-column prop="taxNumber" label="纳税人识别号" width="140"></el-table-column>
              <el-table-column prop="amount" label="开票金额" width="80">
              </el-table-column>
              <el-table-column prop="status" label="开票状态" width="80">
                <template slot-scope="scope">
                  {{ scope.row.status === 1 ? '申请中' : '已开票' }}
                </template>
              </el-table-column>
              <el-table-column prop="sendStatus" label="客户发送状态" width="100">
                <template slot-scope="scope">
                  {{ scope.row.isSend === true ? '已发送' : '未发送' }}
                </template>
              </el-table-column>
              <el-table-column prop="creatorName" label="创建人" width="70"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
              <el-table-column prop="invoiceUserName" label="开票人" width="70"></el-table-column>
              <el-table-column prop="invoiceTime" label="开票时间" width="150"></el-table-column>
              <el-table-column prop="remark" label="系统备注" width="120"></el-table-column>
              <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                  <div v-if="scope.row.status === 1">
                    <el-button @click="handleApproved(scope.row)" type="text" size="small"
                               v-perm="['oms:invoice:audit']">
                      审核通过
                    </el-button>
                  </div>
                  <div v-else>
                    <div v-if="scope.row.isSend">
                      <el-button @click="handleDownloadPic(scope.row)" type="text" size="small">
                        下载发票
                      </el-button>
                    </div>
                    <div v-else>
                      <el-button @click="handleHasBeenSent(scope.row)" type="text" size="small">
                        标记为已发送
                      </el-button>
                      <el-button @click="handleDownloadPic(scope.row)" type="text" size="small">
                        下载发票
                      </el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.page"
                :page-sizes="[10,20,50,100]"
                :page-size="searchForm.size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
          <el-dialog
              title="审核通过"
              :visible.sync="dialogVisible"
              width="30%">
            <el-upload
                :limit="1"
                class="upload-demo"
                ref="upload"
                action="#"
                :multiple="false"
                :http-request="handleUploadFile"
                :auto-upload="false">
              <div class="el-upload__tip" slot="tip">请选择发票文件</div>
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-upload>
            <div slot="footer" class="dialog-footer">
              <el-button plain @click="dialogVisible = false">取消</el-button>
              <el-button type="primary" @click="handleAuditPass" :loading="loadingBtn">确定</el-button>
            </div>
          </el-dialog>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
import { UserInvoiceApi, CommonQiniuApi } from '@/api'
import fileDownload from 'js-file-download'

export default {
  name: 'Invoice',
  data () {
    return {
      // 所有总的查询表单条件
      searchForm: {
        userId: '',
        status: '',
        isSend: '',
        creationTimeStart: '',
        creationTimeEnd: '',
        page: 1,
        size: 10
      },
      total: 0,
      dialogVisible: false,
      tableInfo: [],
      loadForm: {
        invoiceFileUrl: '',
        id: ''
      },
      multipleSelection: [],
      loading: false,
      loadingExp: false,
      loadingBtn: false
    }
  },
  methods: {
    /**
     * 获取表格信息
     */
    handleFind () {
      const params = {
        ...this.searchForm,
        page: this.searchForm.page - 1
      }
      UserInvoiceApi.list(params).then(res => {
        this.total = res.total
        this.tableInfo = res.rows
      })
    },
    handleSizeChange (size) {
      this.searchForm.size = size
      this.handleFind()
    },
    handleCurrentChange (page) {
      this.searchForm.page = page
      this.handleFind()
    },
    /**
     * 通过审核
     * @param val
     */
    handleApproved (val) {
      this.dialogVisible = true
      this.loadForm.id = val.id
    },
    /**
     * 申请开票
     */
    handleGotoInvoiceApply () {
      this.$router.push({ path: '/oms/invoice/apply' })
    },
    /**
     * 上传文件
     */
    handleUploadFile (params) {
      const file = params.file
      this.loadingBtn = true
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((result) => {
        this.loadForm.invoiceFileUrl = result.fileUrl
        this.loadingBtn = false
        UserInvoiceApi.ticket(this.loadForm).then(() => {
          this.dialogVisible = false
          this.handleFind()
        })
      })
    },
    handleAuditPass () {
      this.$refs.upload.submit()
    },
    /**
     * 标记为已发送
     * @param val
     */
    handleHasBeenSent (val) {
      if (val.isSend) {
        this.$message({
          type: 'error',
          message: '已是已发送状态，无需点击修改'
        })
        return
      }
      UserInvoiceApi.send(val.id).then(res => {
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.handleFind()
      })
    },
    handleDownloadPic (val) {
      window.open(val.invoiceFileUrl)
    },
    /**
     * 下载发票按钮
     */
    handleBatchDownloadPic () {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'error',
          message: '请勾选要下载的信息'
        })
        return
      }
      const newList = this.multipleSelection.filter(it => it.status === 2).map(it => it.id)
      if (newList && newList.length > 0) {
        this.loading = true
        UserInvoiceApi.download(newList).then(res => {
          this.loading = false
          const data = new Blob([res.data])
          const fileName = `${(new Date()).getTime()}.zip`
          fileDownload(data, fileName)
        })
      } else {
        this.$message.error('没有可下载的发票')
      }
    },
    /**
     * 多选框
     * @param val
     */
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    handleExport () {
      const params = {}
      if (this.multipleSelection.length === 0) {
        params.query = {
          ...this.searchForm,
          size: 0
        }
      } else {
        params.ids = this.multipleSelection.map(it => it.id)
      }
      this.loadingExp = true
      UserInvoiceApi.excel(params).then(res => {
        this.loadingExp = false
        const data = new Blob([res.data])
        const fileName = '发票抬头信息.xls'
        fileDownload(data, fileName)
      })
    }
  },
  created () {
    this.handleFind()
  }
}
</script>

<style scoped>
</style>
